/* layout */
.private_page_wrapper {
  display: flex;
  height: 100%;
  width: 100%;
  overflow: auto;  
  padding: 0px;
  justify-content: flex-start;
  flex-direction: column;
}
.private_page_wrapperNoFlex {
  display: block;
  height: 100%;
  width: 100%;
  overflow: auto;  
  padding: 0px;
}

@media screen and (min-width: 1300px) {
  .private_page_wrapperNoFlex .pdl_wrapper
  {
    width: 100%;
  }
}
.private_page_wrapperR {
  display: flex;
  height: 100%;
  width: 50%;
  overflow: auto;  
  padding: 0px;
  justify-content: flex-start;
  flex-direction: column;
  float : left ; 

}

.private_page_wrapperM {
  display: flex;
  height: 100%;
  width: 100%;
  overflow: auto;  
  padding: 0px;
  justify-content: flex-start;
  flex-direction: column;
}

/* components */
.private_page_wrapper .header {
  padding: 10px;
  display: flex;
  flex-direction: row;
  width: 100%;
}

.private_page_wrapper .header .sub_header_title {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}

.private_page_wrapper .header .sub_header_action {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.private_page_wrapper .header .sub_header_action .ant-btn {
  margin: 0px 5px 0px 5px; 
}

.private_page_wrapper .data_list_toolbar {
  display: flex;
  width: 50vw;
  
}

.private_page_wrapper .search_criteria {
  padding: 10px;
  width: 50vw;
}
.private_page_wrapper .action_toolbar {
  padding: 10px;
  width: 100vw;
  display: flex;
  justify-content: flex-end;

}

.private_page_wrapper .data_list {
  /*background-color: #E5E5E5;*/
  /*width: 100%;*/
  /*height: 100vh;*/

}

.private_page_wrapper .data_listP {
  background-color: #E5E5E5;
  width: 50vw;
  height: 200vh;
  position: relative;
   top: -80%;
  
  justify-content: flex-end;


}

.private_page_wrapper .data_list_toolbarP {
  display: flex;
  width: 55vw;
  
}



.private_page_wrapper .btn {
  background-color: goldenrod;
  border-radius: 50%;
  width: 95px;
  height: 95px;
	top: 40%; 
  left: 40%;
  padding: '20px';
  justify-content: flex-end;
  margin-right: 50px;
  cursor: pointer;
  transform: scale(0.5.1);


}


.private_page_wrapper .switchB {
  position: absolute;

	top: 1%; 
  left: 67%;
  width: 110px;
  height: 30px;
	top: 10%; 
  

	-webkit-transform: translate3d( -50%, -50%, 0 );
	transform: translate3d( -50%, -50%, 0 );
  transform: scale(1.3);
    background-Color: 'goldenrod';


}


/* titles */
.private_page_wrapper .title {
  text-transform: uppercase;
  font-size: 20px;
  font-family: var(--orbitron_font);
  font-weight: bold;
  letter-spacing: 2px;
  margin-bottom: 20px;
}

.private_page_wrapper .subtitle {
  font-size: 20px;
  font-family: var(--common_font);
}



/* form */
.common_form {
  max-width: 700px;
}
.common_form legend{
  padding-left: 20px;
}

/* TEST  */
.private_page_wrapperM .header {
  padding: 10px;
  display: flex;
  flex-direction: column;
}

.private_page_wrapperM .subtitle {
  font-size: 14px;
  font-family: var(--common_font);
}

.private_page_wrapperM .data_list {
  background-color: #E5E5E5;
  width: 50%;
  height: 100%;
  position: 'relative';
  left: '50px';
}


.private_page_wrapperM .data_list_toolbar {
  display: flex;
  width: 50%;
}



.private_page_wrapperM .search_criteria {
  padding: 10px;
  width: 50%;
}
.private_page_wrapperM .action_toolbar {
  padding: 10px;
  width: 10%;
  display: flex;
  justify-content: flex-end;
}



/* titles */
.private_page_wrapperM .title {
  text-transform: uppercase;
  font-size: 20px;
  font-family: var(--orbitron_font);
  font-weight: bold;
  letter-spacing: 2px;
  margin-bottom: 10px;
}

.private_page_wrapperM .subtitle {
  font-size: 14px;
  font-family: var(--common_font);
}

/* form */
.common_form {
  max-width: 700px;
}