/* page ---------------------------------------------------------- */
html,
body {
  padding: 0;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen,
    Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
  background-color: black;
  height: 100%;
  display: flex;
}
body #root {
  width: 100%;
  overflow-y: hidden;
}
a {
  color: inherit;
  text-decoration: none;
}
* {
  box-sizing: border-box;
}


/* button -------------------------------------------------------- */
.ant-btn,
.ant-btn:focus,
.ant-btn:active {
  background-color: var(--gold);
  border: 1px solid var(--gold);
  color: white;
  border-radius: 4px;
  height: 35px;
}
.ant-btn:hover {
  background-color: var(--gold_hover);
  border: 1px solid var(--gold_hover);
  color: white;
}

.ant-btn.success {
  background-color: var(--green);
  border: 1px solid var(--green);
}
.ant-btn.success:hover {
  background-color: var(--green_hover);
  border: 1px solid var(--green_hover);
}

.ant-btn.error {
  background-color: var(--red);
  border: 1px solid var(--red);
}
.ant-btn.error:hover {
  background-color: var(--red_hover);
  border: 1px solid var(--red_hover);
}

/* header -------------------------------------------------------- */
.ant-layout-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: var(--black);
}
.ant-layout-header img {
  max-height: 50px;
}
.ant-layout-header.ant-menu-horizontal {
  background-color: var(--black);
}
.ant-layout-header .ant-menu-item-selected, 
.ant-layout-header  .ant-menu-item-selected,
.ant-layout-header .ant-menu-horizontal .ant-menu-item:hover {
  background-color: var(--gold);
}

/* debug header -------------------------------------------------- */
.debug_mode_header {
  background-color: red;
  color: white;
  font-weight: bold;
  height: 40px;
  font-size: 25px;
  justify-content: center;
}


/* layout -------------------------------------------------------- */
.ant-layout {
  height: 100%;
}

/* loader -------------------------------------------------------- */
.ant-spin {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;  
}

/* font-size ----------------------------------------------------- */
body {
  font-size: 16px;
}
.ant-table {
  font-size: 16px;
}
.ant-picker-input > input, .ant-select {
  font-size: 16px;
}
