.map_pilotage_toolbar {
  position: absolute;
  background-color: rgba(0,0,0,0.8);
  left: 50%;
  width: 50%;
  display: flex;
  flex-direction: column;
  color: white;
}

/* header */
.map_pilotage_toolbar_header {
  padding: 20px 30px 15px 30px;
  /*padding-left: 20px;*/
  display: flex;
  justify-content: space-between;
}
.map_pilotage_toolbar_header .next_start {
  font-weight: bold;
}
.map_pilotage_toolbar_header .map_track_switch {
  font-weight: bold;
}

/* buttons */
.map_pilotage_toolbar_button_wrapper {
  padding: 10px 30px 10px 30px;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  width: 100%;
  flex-wrap: wrap;
}
.map_pilotage_toolbar_button_wrapper .tlb-btn-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.map_pilotage_toolbar_button_wrapper .tlb-btn {
  background-color: var(--gold);
  border-radius: 50%;
  width: 50px;
  height: 50px;
  cursor: pointer;
}
.map_pilotage_toolbar_button_wrapper .tlb-btn.active {
  background-color: green;
  border: 2px solid white;
}
.map_pilotage_toolbar_button_wrapper .tlb-btn.disable {
  background-color: black;
  border: 2px solid white;
  color: white;
  cursor: default;
}
.map_pilotage_toolbar_button_wrapper .tlb-btn span {
  font-size: 25px;
}
.map_pilotage_toolbar_button_wrapper span {
  padding-top: 5px;
  /* font-weight: bold; */
  text-transform: uppercase;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

/* map_pilotage_toolbar_footer */
.map_pilotage_toolbar_footer {
  padding: 10px 30px 10px 30px;
}
.map_pilotage_toolbar_footer .next_start span {
  text-transform: uppercase;
  color: var(--gold);
}

/* countdown */
.next_start .label {
  padding: 0;
}
.next_start_hidden {
  visibility: hidden;
}
.map_markers_drawer_btn {
  display: flex;
  position: absolute;
  bottom:0;
  right:23%;
}

#map_control .item {
  font-size: 16px;
  display: inline-block;
  width: 360px;
  vertical-align: top;
  text-align: right;
}
#map_control .item.item-double {
  width: 100%;
  text-align: center;
}

#map_control .item.item-double .slider {
  width: 439px;
  display: inline-block;
  vertical-align: middle;
}

#map_control .item .label {
  margin-right: 5px;
  text-align: right;
}

#map_control .item .switch {
  margin-right: 20px;
  text-align: right;
}


#force-network-switch-container {
  float: right;
}

#force-network-switch {
    background-color: var(--gold);
}
