/* page ---------------------------------------------------------- */
.race_live_wrapper {
  background-color: #262626;
  color: white;
  min-height: 100%;
  display: flex;
  flex-direction: column;
  padding: 10px;
}

/* titles & font -------------------------------------------------- */
.race_live_wrapper h1 {
  color: white;
  font-weight: bold;
}
.race_live_wrapper h2 {
  color: white;
  font-weight: bold;
  font-size: 1.8vw;
}
.title_prefix {
  color: var(--grey);
  font-weight: normal;
  margin-right: 5px;
}
.rc_block {
  background-color: red;
  padding: 5px;
  border-radius: 6px;
  margin-left: 5px;
  margin-right: 5px;
}
.race_live_wrapper h1.no_race {
  color: var(--gold);
  font-size: 20px;
}

/* 1600 to more */
@media screen and (min-width: 1601px) {
  .race_live_wrapper h1 {
    font-size: 30px;
  }
  .race_info_title {
    font-size: 20px;
  }  
  .race_info_comment_label {
    font-size: 16px;
  }
  .race_info_comment {
    font-size: 14px;
  }
}

/* 701px to 1600px */
@media screen and (min-width: 701px) and (max-width: 1600px) {
  .race_live_wrapper h1 {
    font-size: 2vw;
  }
  .race_info_title {
    font-size: 20px;
  }  
  .race_info_comment_label {
    font-size: 16px;
  }
  .race_info_comment {
    font-size: 14px;
  }
}

/* 700px wide or less */
@media screen and (max-width: 700px) {
  .race_live_wrapper h1 {
    font-size: 3vw;
    text-align: center;
  }
  .race_info_title {
    font-size: 15px;
  }  
  .race_info_comment_label {
    font-size: 12px;
  }
  .race_info_comment {
    font-size: 12px;
  }
}

/* header -------------------------------------------------------- */
.race_live_header {
  height: 30px;
  display: flex;
  justify-content: space-between;
}

/* race_live_panel_info ------------------------------------------ */
.race_live_panel_info {
  display: flex;
  margin-top: 30px;
  margin-bottom: 30px;
}
.race_info_wrapper {
  width: 40%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.race_info_panel {
  border: 0 solid var(--gold);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
}
.race_info_title {
  font-weight: bold;
  margin-bottom: 10px;
  color: var(--gold);
}
.race_info_comment_label {
  font-weight: normal;
  margin-right: 5px;
  text-transform: uppercase;
  color: var(--grey);
}
.race_info_comment {
  padding: 10px 30px 0 0;
  text-align: justify;
}
.race_ranking_panel {
  width: 40%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.race_ranking_panel span.title {
  font-size: 2vw;
  font-weight: bold;
  background: var(--dark_grey);
  width: 100%;
  text-align: center;
  color: var(--gold);
}
.block_top_5 {
  border: 3px solid var(--gold);
  border-radius: 5px;
  padding: 5px;
  background-color: #000;
}
.block_top_5 .ant-table-tbody > tr.ant-table-row:hover > td {
  background-color: black;
}
.block_top_5 .ant-table-thead {
  border: 0 solid white;
  border-collapse: collapse;
}
.block_top_5 .ant-table-thead .ant-table-cell {
  color: white;
  background-color: black;
  font-size: 12px;
  font-weight: normal;
  padding: 0;
  margin: 0;
  text-align: center;
}
.block_top_5 .ant-table-tbody .ant-table-cell {
  background-color: black;
  font-size: 14px;
  font-weight: normal;
  color: white;
  border: 0;
  padding: 5px 0 0 0;
}

.block_top_5 .ant-table-tbody .ant-table-cell .ant-empty {
  visibility: hidden;
}
.block_top_5 .ant-table-tbody .ant-table-cell:hover {
  background-color: #000;
}
  
.block_top_5 .ant-table-thead .ant-table-cell.sec-col-rank,
.block_top_5 .ant-table-tbody .ant-table-cell.sec-col-rank {
  width: 10%;
  text-align: center;
}
.block_top_5 .ant-table-thead .ant-table-cell.sec-col-num,
.block_top_5 .ant-table-tbody .ant-table-cell.sec-col-num {
  width: 10%;
  text-align: center;
}
.block_top_5 .ant-table-thead .ant-table-cell.sec-col-runner,
.block_top_5 .ant-table-tbody .ant-table-cell.sec-col-runner {
  width: 40%;
  text-align: left;
  font-weight: bold;
}
.block_top_5 .ant-table-tbody .ant-table-cell.sec-col-timefirst {
  text-align: center;
  width: 15%;
}
.block_top_5 .ant-table-tbody .ant-table-cell.sec-col-time {
  text-align: center;
  width: 15%;
}
.top5_tooltip .ant-tooltip-content {
  background-color: white;
}

/* race_progression ---------------------------------------------- */
.race_progression_wrapper {
  width: 20%;
  padding: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.race_progression_wrapper .race_progression_panel {
  border: 0 solid white;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 10px;
  width: 10vw;
}
.race_progression_wrapper .race_progression_panel span.racetrack_title {
  font-size: 2vw;
}
.race_progression_wrapper .race_progression_panel span.racetrack_value {
  background: var(--dark_grey);
  color: var(--gold);
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 3vw;
  font-weight: bold;
  /* height: 7vw; */
  border: 0 solid var(--gold);
}

/* countdown_block ----------------------------------------------- */
.countdown_block {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: 35px;
}
.countdown_block span.label {
  font-size: 25px;  
}

/* sec_block ----------------------------------------------------- */
.sec_block_title {
  color: white;
}
.sec_block_wrapper {
  display: flex;
  width: 100%;
  /* border: 1px solid var(--gold); */
  padding: 5px;
  min-height: 400px;
  background-color: black;
  justify-content: space-between;
}
.sec_block_past {
  display: flex;
  width: calc(100% - 310);
  border: 1px solid rgb(53, 52, 52);
  overflow: auto;
}

.sec_block_col {
  display: flex;
  flex-direction: column;
  align-items: center;
  border-right: 1px solid white;
  width: 310px;
}
.sec_block {
  width: 300px;
}
.sec_block_pc {
  width: 100%;
  height: 30px;
  text-align: center;
  font-weight: bold;
  font-size: 16px;
}
.sec_block .ant-table-tbody > tr.ant-table-row:hover > td {
  background-color: black;
}
.sec_block .ant-table-thead {
  border: 0 solid white;
  border-collapse: collapse;
}
.sec_block .ant-table-thead .ant-table-cell {
  color: white;
  background-color: black;
  font-size: 12px;
  font-weight: normal;
  padding: 0;
  margin: 0;
  text-align: center;
}
.sec_block .ant-table-tbody .ant-table-cell {
  background-color: black;
  font-size: 14px;
  font-weight: normal;
  color: white;
  border: 0;
  padding: 5px 0 0 0;
}
.sec_block .ant-table-thead .ant-table-cell.sec-col-rank,
.sec_block .ant-table-tbody .ant-table-cell.sec-col-rank {
  width: 20px;
  text-align: center;
}
.sec_block .ant-table-thead .ant-table-cell.sec-col-num,
.sec_block .ant-table-tbody .ant-table-cell.sec-col-num {
  width: 20px;
  text-align: center;
}
.sec_block .ant-table-thead .ant-table-cell.sec-col-timefirst,
.sec_block .ant-table-tbody .ant-table-cell.sec-col-timefirst {
  width: 40px;
  text-align: center;
}
.sec_block .ant-table-thead .ant-table-cell.sec-col-time,
.sec_block .ant-table-tbody .ant-table-cell.sec-col-time {
  width: 40px;
  text-align: center;
}
.sec_block .ant-table-thead .ant-table-cell.sec-col-maxspeed,
.sec_block .ant-table-tbody .ant-table-cell.sec-col-maxspeed {
  width: 40px;
  text-align: center;
}
/* .sec_block .ant-table-tbody .ant-table-placeholder {
  display: none;
  visibility: hidden;
} */

/* sec_block live ------------------------------------------------ */
.sec_block_pc_live {
  width: 100%;
  background-color: red;
  height: 30px;
  text-align: center;
  font-size: 16px;
  font-weight: bold;
}
.sec_block_col.live {
  background-color: white;
  width: 350px;
  border: 1px solid white;
  height: 100%;
}
.sec_block.live {
  padding-bottom: 10px;
  width: 350px;
}
.sec_block.live .ant-table-thead .ant-table-cell {
  background-color: white;
  font-size: 12px;
  font-weight: bold;
  color: black;
}
.sec_block.live .ant-table-tbody .ant-table-cell {
  background-color: white;
  font-size: 16px;
  font-weight: bold;
  color: black;
}
.sec_block.live .ant-table-tbody > tr.ant-table-row:hover > td {
  background-color: white;
}

/* next_race ----------------------------------------------------- */
.next_race {
  display: flex;
  width: 100%;
  justify-content: flex-end;
}