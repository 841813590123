
.terminal_console { 
  margin-top: 20px;
  margin-bottom: 20px;
  background-color: black;
  background-image: radial-gradient(
    rgba(0, 150, 0, 0.25), black 120%
  );
  color: white;
  font: 1rem Inconsolata, monospace;
  text-shadow: 0 0 5px #C8C8C8;
  opacity: 60%;
  bottom: 0px;
  right: 0px;
  width: 100%;
  border-radius: 5px;
  padding: 20px;
  line-height: 25px;
  min-height: 200px;
  max-height: 500px;
  overflow: auto;
}
.terminal_console:after {
  content: "";
  /* position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh; */
  background: repeating-linear-gradient(
    0deg,
    rgba(black, 0.15),
    rgba(black, 0.15) 1px,
    transparent 1px,
    transparent 2px
  );  
}
.terminal_console p {
  padding: 0px;
  margin: 0px; 
}
