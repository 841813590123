/* list ---------------------------------------------------------- */
.track_list {
  margin-top: 20px;
}
.track_list_search {
  padding: 20px 20px 0px 20px;
}

.interpolation_modal_msg_wait {
  display: flex;
  padding: 0 0 30px 0;
  justify-content: center;
}