.private_layout .sider {
  background-color: black;
}
.private_layout .sider .logo {
  width: 100%;
  display: flex;
  justify-content: center;
  padding: 10px;
}
.private_layout .sider .logo img {
  width: 90%;
}
/* menu */
.menu_wrapper {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex-grow: 1;
}
.top_menu {
  flex-grow: 1;
}
.private_layout .sider .menu {
  /* margin-top: 20px; */
  background-color: black;
  color: white;
  border: 0;
}
.private_layout .ant-layout-sider-children {
  display: flex;
  flex-direction: column;
}
.private_layout .sider .ant-menu-inline-collapsed > .ant-menu-item .ant-menu-item-icon {
  font-size: 25px;
}
.private_layout .sider .menu .ant-menu-item {
  display: flex;
  padding-left: 33%;
}
.private_layout .sider .menu .ant-menu-item:hover, 
.private_layout .sider .menu .ant-menu-item-active,
.private_layout .sider .menu .ant-menu-item-selected {
  background-color: var(--gold);
  color: white;
}

/* layout */
.private_layout .ant-layout {
  background-color: white;
}

.menu.middle{
  font-family: var(--orbitron_font);
  font-size: 13px
}

.menu.middle .server {
  background-color: #666666;
  /* margin: 0 0 50% 0; */
  text-align: center;
  padding: 10px 0 10px 0;
}
.menu.middle .server .name {
  color: #0acf65;
}
.menu.middle .server .label {
  color: white;
}
.menu.middle .auto-monitoring-select {
  width: 100%;
  font-size: 14px;
  color: #0acf65;
  border-color: #0acf65;
  padding: 0;
}
.menu.middle .ant-select-arrow, .menu.middle .ant-select-clear {
  color: #0acf65;
  background-color: #555;
}
.menu.middle .auto-monitoring-select .ant-select-selector{
  background-color: #555;
  border-color: #0acf65;
  margin-bottom: 5px;
}

.menu.middle .auto-monitoring {
   background-color: #666666;
   padding: 10px 0 10px 0;
   text-align: center;
}

.event-list {
  /* margin: 0 0 50% 0; */
  width: 100%;
  text-align: center;
}
.event-list button {
  background-color: #666666;
  border-color: #666666;
  border-radius: 0;
  padding: 0;
  width: 100%;
}


.event-list button:hover {
  background-color: #888888;
  border-color: #888888;
}