@import url("https://fonts.googleapis.com/css?family=Orbitron");

:root {
  --gold: #DBAC58;
  --gold_hover: #e0a642;
  --black: #000000;
  --grey: #757575;
  --dark_grey: #262626;
  --green: #77b594;
  --green_hover: #37bb75;
  --red: #d12323;
  --red_hover: #b31111;

  /* public site */
  --grey_bg: #EEEEEE;
  --grey_font: #666666;


  /* font */
  --orbitron_font: 'Orbitron', sans-serif;
  --common_font: 'Roboto', sans-serif;

}