/* page ---------------------------------------------------------- */
.race_timeline_wrapper {
  padding: 20px;
  margin: 0;
  height: 100%;
  position: relative;
  overflow: auto;
  background-color: #262626;
  color: white;
}

/* header -------------------------------------------------------- */
.race_timeline_header {
  display: flex;
  align-items: center;
  width: 100%;
  height: 60px;
  flex-grow: 1;
  padding: 10px;
}
.race_timeline_header h1 {
  color: black;
  display: flex;
  justify-content: left;
  font-weight: bold;
  font-size: 20px;

}

/* header right */
.race_timeline_header_clock {
  display: flex;
  padding-left: 900px;
}
.race_timeline_header .btn_live {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 10px 0 10px;
  width: 70px;
  background-color: var(--red);
  font-weight: bold;
  color: white;
}
.race_timeline_header .btn_live:hover {
  background-color: var(--red_hover);
  font-weight: bold;
  color: white;
}
.race_timeline_header_clock span {
  margin: 0 10px 0 15px;
  font-size: 18px;
  font-weight: bold;
}

/* timeline ------------------------------------------------------ */
.race_timeline_content {
  padding-left: 5px;
  padding-right: 5px;
  display: flex;
  flex-direction: row;
  background-color: black;
  border: 1px solid #262626;
}
.race_timeline_hours {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100px;
}
.race_timeline_hours .hour_label {
  height: 60px;
  display: flex;
  width: 100px;
  justify-content: center;
  align-items: center;
  background-color: black;
  border-right: 1px solid #262626;
}
.race_timeline_hours .hour_label:not(:last-child) {
  border-bottom: 1px solid #262626;
}
.race_timeline_wrapper .time_line {
  height: 2px;
  background-color: red;
  position: absolute;
  width: calc(100% - 140px);
  left: 120px;
}
.race_timeline_header_meeting {
  padding-left: 10px;
  height: 150px;
  display: flex;
  font-weight: bold;
  font-size: 13px;
}

.race_timeline_header_meeting2 {
  padding-left: 10px;
  height: 20px;
  display: flex;
  font-weight: bold;
  font-size: 13px;
}

.race_timeline_header_meeting2 h1 {
  padding-left: 10px;
  height: 20px;
  display: flex;
  font-weight: bold;
  font-size: 18px;
}


.race_timeline_header_Program {
  padding: 10px 0 10px 0;
  /* height: 0px; */
  display: flex;
  font-weight: bold;
  font-size: 13px;
  color:goldenrod;
}

.race_timeline_header_Program h1 {
  padding-left: 0;
  height: 30px;
  display: flex;
  font-weight: bold;
  font-size: 20px;
  color:goldenrod;
}
.race_timeline_header_meeting .item {
  display: flex;
  flex-direction: column;
  width: 200px;
  font-weight: bold;
  background-color: white;
  height: 9px;
}
.race_timeline_meeting {
  width: 200px;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-right: 1px solid #262626;
}
.race_block {
  width: 100px;
  height: 25px;
  background-color: #666;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  cursor: pointer;
}
.race_block.active {
  background-color: red;
}


/* popover */
.popover_race_number {
  background-color: red;
  color: white;
  padding: 4px;
  margin-right: 5px;
}
.popover_content_wrapper .race_name {
  font-weight: bold;
}
.popover_content_wrapper p {
  display: flex;
  justify-content: flex-start;
}
.popover_content_wrapper .p_icon {
  width: 30px;
  display: flex;
  justify-content: flex-start;
  font-weight: bold;
}
.popover_content_wrapper .p_icon img {
  width: 20px;
}
.popover_content_wrapper .p_label {
  width: 100px;
  display: flex;
  justify-content: flex-start;
  font-size: small;
}
.popover_content_wrapper .p_val {
  display: flex;
  justify-content: flex-start;
  font-size: small;
}

.programme .unknown_track{
  background-color: #FC5050;
}
.ant-table-tbody > tr > td > .ant-table-wrapper.competitor_list .ant-table{
  margin : 20px;
  background-color: white;
}
.pdl_wrapper .ant-table-wrapper.competitor_list {
  background-color: #eee;
}
.competitor_list .ant-table-thead .ant-table-cell {
    background-color:#baf1d4;
    font-size: 14px;
}
.race_list td.ant-table-cell {
  font-size: 15px;
}
.competitor_list td.ant-table-cell {
  font-size: 14px;
  border-right-color: #8d8d8d;

  border-right-style: dashed;
  border-right-width: 1px;
}
.competitor_list td.ant-table-cell p,
.race_list td.ant-table-cell p{
  margin-left: 7px;
  margin-right: 7px;
}