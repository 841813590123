/* page ---------------------------------------------------------- */
.pilotage_page_wrapper {
  min-height: 100%;
  display: flex;
  flex-direction: row;
  padding: 0;
  position: relative;
}

.pilotage_panel_data {
  display: flex;
  flex-direction: column;
  width: 50%;
  min-width: 400px;
  max-width: 900px;
  padding: 0;
  /* border: 1px solid red; */
  overflow: auto;
}

.pilotage_panel_map {
  display: flex;
  flex-direction: row;
  flex-grow: 1;
  /* border: 1px solid blue; */
}

/* header -------------------------------------------------------- */
.pilotage_panel_data .header {
  width: 100%;
  padding: 10px 10px 10px 20px;
}
.pilotage_panel_data .header .title {
  margin-bottom: 15px;
}
.pilotage_panel_data .header .title .btn-simple {
  background-color: white;
  border-width: 0px;
  color: lightgray;
  height: 20px;
  vertical-align: baseline;
  margin-left: 20px;
}
.pilotage_panel_data .header .title .btn-simple:hover {
  color: gray;
}
.pilotage_panel_data .header .subtitle {
  font-size: 18px;
}
.header .sub_header_action .ws_connected,
.header .sub_header_action .ws_unconnected {
  font-size: 25px;
}
.header .sub_header_action .ws_connected {
  color: green;
}
.header .sub_header_action .ws_unconnected {
  color: red;
}


/* data_list ----------------------------------------------------- */
.pdl_wrapper {
  padding: 0;
  background-color: #EEEEEE;
  flex-grow: 1;
  display: inline-block;
}
.pdl_wrapper .ant-table-wrapper {
  background-color: #E3E9EE;
}

.pilotage_list .editable-row.disabled {
  background-color: #CCCCCC;
  color: #8d8d8d;
}

.pilotage_list .ant-select {
  padding: 0 ;
  font-size: 13px;
  text-align: center;
}

.pilotage_list .ant-table-cell {
  padding: 0 ;
  font-size: 13px;
  text-align: center;
}

.pilotage_list .capt_cell {
  display: flex;
  flex-direction: column;
}

.pilotage_list .capt_cell span {
  text-align: right;
}

.pilotage_list .capt_cell .tracker_on {
  color: green;
}
.pilotage_list .capt_cell .tracker_off {
  color: red;
}
/*.pilotage_list .th_fus {*/
/*  display: flex;*/
/*  align-items: center;*/
/*  justify-content: space-between;*/
/*}*/
.pilotage_list .capt_cell .race_mode {
  color: green;
  font-weight: bold;
}
.rankcont {
  display: flex;
  flex-direction: column;
  cursor: pointer;
  width: 100%;
  height: 100%;
}
.rankcont:hover
{
  background-color: var(--gold_hover);
}
.white
{
  color: white;
}
.white:hover
{
  color: var(--gold_hover);
}

/* tracker_table ------------------------------------------------- */
.private_page_wrapper .data_list.programme.tracker_table_wrapper {
  display: flex;
  overflow: auto;
  padding: 20px;
}

.tracker_table {
  max-width: 450px;
  margin: 0 10px;
}

.tracker_table .cell_fusion_wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.tracker_table .cell_fusion_wrapper .ant-select {
  width: 75px;
}

.tracker_table .cell_fusion_wrapper .ant-select,
.tracker_table .cell_fusion_wrapper .ant-btn {
  margin: 2px 5px;
}

.tracker_race_number {
  padding-bottom: 20px;
  display: flex;
  justify-content: center;
  width: 100%;
  color: red;
  font-weight: bold;
}
.reset_all_fusion_button{
  height: 30px;
  margin-left: 10px
}
.tracker_table_no_record {
  display: flex;
  justify-content: center;
  width: 100%;
  font-size: 30px;
  color: #666666;
  height: 100%;
  align-items: center;
}

/* programme tmp ------------------------------------------------- */
.psc_row.toolbar .toolbar_btn {
  margin: 5px 0 5px 20px;
}
.pdl_wrapper .data_list.programme {
  padding: 20px 20px 0 20px;
}
.pdl_wrapper .data_list.programme .list_search {
  display: flex;
  padding: 10px 0 10px 0;
  max-width: 500px;
}

.data_list .ant-collapse {
  border-bottom: none;
  border-top: none;
  border-left: none;
  border-right: 1px solid #cccccc;
}

.data_list .ant-collapse > .ant-collapse-item  {
  border-bottom: none;
}

.data_list .ant-collapse-content {
  border-top: none;
  background-color: inherit;
}

.data_list .ant-collapse-content > .ant-collapse-content-box {
  padding-top: 10px;
}

.IN_CHARGE {
  background-color: #b1b1b1;
  color: black;
}
.ALERT {
  background-color: #dbac58;
  color: black;
}
.WARNING {
  background-color: #ffa9b5;
  color: black;
}
.LIVE {
  /*background-color: #dbac58;*/
}
.NOT_APPLICABLE {
  color: #ff263e;
}
.RAW {
  color: #6600ff;
}
.RACE {
  color: #20d386;
}
.NUL {
  color: #ff3b36;
}
.IS_ARRIVED{
  color: #4c4c4c;
}
.RTK{
  color: #46eba1;
}
.RTK_FLOAT{
  color: #81c045;
}
.NO_FIX{
  color: #4c4c4c;
}
.GPS{
  color: #4c4c4c;
}
.DGPS{
  color: #d9834d;
}


.divtoolmap
{
  position: relative;
  width: 100%;
}


/* manual intervention  ------------------------------------------------- */
.pdl_wrapper
{
  position: relative;
}
.manual_dlgcont
{
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-color: rgba(0,0,0,0.3);
  z-index: 50;
}
.manual_dlg
{
  position: fixed;
  top: 15px;
  left: calc(50% - 200px);
  width: 300px;
  background-color: white;
  border: 1px solid #F0F0F0;
  color: black;
  z-index: 1000;
  padding: 5px 10px;
}
.manual_show
{
  display: block;
}
.manual_hide
{
  display: none;
}
.manual_title
{
  font-weight: bold;
  text-align: center;
  width: 100%;
  margin-bottom: 25px;
}
.manual_close
{
  position: absolute;
  top: 5px;
  right: 5px;
  border: 1px solid #F0F0F0;
  background-color: var(--gold);
  font-size: 12px;
  padding: 0 2px;
  cursor: pointer;
}
.manual_close:hover
{
  background-color: var(--gold_hover);
}
.manual_line
{
  display: flex;
  margin-bottom: 7px;
}
.manual_label
{
  padding-right: 5px;
  width: 110px;
}
.manual_input
{
  width: 100px;
}
.manual_send
{
  border: 1px solid #F0F0F0;
  background-color: var(--gold);
  padding: 0 2px;
  cursor: pointer;
  width: 100px;
  margin: 20px auto 10px auto;
  text-align: center;
}
.manual_send:hover
{
  background-color: var(--gold_hover);
}
