/* ========================================================================= */
/* Login */
/* ========================================================================= */
@media screen and (max-width: 1000px) {
  .public_layout {
    padding: 40px;
  }
}
@media screen and (max-width: 700px) {
  .public_layout {
    padding: 10px;
  }
  .login_overlay {
    width: 100%;
  }
  .login_form .login_input_text .ant-input-affix-wrapper {
    padding: 10px;
  }
}
@media screen and (max-height: 550px) {
  .public_layout_header {
    display: none;
  }
  .login_form .login_input_text .ant-input-affix-wrapper {
    padding: 5px;
  }
  .ant-card-body {
    padding: 5px;
  }
}
/* ========================================================================= */
/* Map */
/* ========================================================================= */
@media screen and (max-width: 1600px) {
  .map_pilotage_toolbar {
    font-size: 80%;
  }
  .map_pilotage_toolbar_header {
    padding: 15px 20px 10px 20px;
  }
  .map_pilotage_toolbar_footer {
    padding: 5px 20px 5px 20px;
  }
}
@media screen and (max-width: 1250px) {
  .map_pilotage_toolbar {
    font-size: 70%;
  }
  .map_control_body .ant-drawer-body{
    padding: 10px;
  }
}
@media screen and (max-width: 1000px) {
  .map_pilotage_toolbar {
    font-size: 60%;
    width: 100%;
    left: 0;
  }
  .pilotage_page_wrapper {
    display: block;
  }
  .map_pilotage_toolbar_header {
    padding: 5px 10px 5px 10px;
  }
  .pilotage_panel_data {
    width: 100%;
    min-width: unset;
  }
  .pilotage_panel_map {
    height: 700px;
  }
  .map_pilotage_toolbar_button_wrapper {
    padding: 5px 20px 5px 20px;
  }
  .map_pilotage_toolbar_button_wrapper .tlb-btn {
    width: 20px;
    height: 30px;
  }
  .map_pilotage_toolbar_button_wrapper span {
    padding-top: 0px;
    font-size: unset;
  }
  .map_pilotage_toolbar_button_wrapper .tlb-btn span  {
    font-size: unset;
  }
  #map_control .item {
    font-size: 12px;
    width: 300px;
  }
}
@media screen and (max-width: 500px) {
  .map_pilotage_toolbar {
    font-size: 50%;
  }
}
