.psc_wrapper {
  /* padding: 10px 20px 10px 20px; */
  padding: 0px 20px;
}
.psc_row {
  padding: 0 0 10px 0;
  display: flex;
}
.psc_icon {
  padding: 8px;
  border: 1px solid #CCCCCC;
  border-right: none;
  border-radius: 0;
  background: #d9d9d9;
}
.psc_input {
  border-radius: 0;
  background-color: #eee;
}
.psc_input.select {
  width: 100%;
}
.psc_input.select .ant-select-selector {
  background-color: #eee;
}
.psc_input.select .ant-select-item-option-content,
.psc_input.select .ant-select-selection-item {
  display: flex;
  flex-direction: row;
}
.psc_input.select span.race_number,
.ant-select-item-option-content span.race_number {
  display: inline-block;
  font-weight: bold;
  color: red;
  border-right: 2px solid red;
  background-color: rgb(241, 219, 219);
  width: 40px;
  padding: 2px 2px 2px 5px;
  margin-right: 10px;
}
.psc_input.select span.race_time,
.ant-select-item-option-content span.race_time {
  font-weight: bold;
  padding: 0 3px;
  color:rgb(51, 83, 185)
}
.psc_input.select span.race_time.crossed .time,
.ant-select-item-option-content span.race_time.crossed .time {
  text-decoration: line-through;
  font-weight: normal;
  color:rgb(78, 78, 78)
}

.psc_input.select span.race_time .gnet,
.ant-select-item-option-content span.race_time .gnet {
  font-weight: bold;
  padding: 0 3px;
  color:rgb(36, 131, 49)
}